import { render, staticRenderFns } from "./WeaponStats.vue?vue&type=template&id=899e83fc&scoped=true&"
import script from "./WeaponStats.vue?vue&type=script&lang=js&"
export * from "./WeaponStats.vue?vue&type=script&lang=js&"
import style0 from "./WeaponStats.vue?vue&type=style&index=0&id=899e83fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "899e83fc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2891420128/src/web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('899e83fc')) {
      api.createRecord('899e83fc', component.options)
    } else {
      api.reload('899e83fc', component.options)
    }
    module.hot.accept("./WeaponStats.vue?vue&type=template&id=899e83fc&scoped=true&", function () {
      api.rerender('899e83fc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Table/WeaponStats.vue"
export default component.exports